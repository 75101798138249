import React from 'react';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  BedFrameWarrantyHeroSection,
  BedFrameWarrantyIntroSection,
  BedFrameWarrantyContentSection,
  BedFrameWarrantyFooterSection,
} from '../../../components/sections';
import { CountryCode } from '../../../utils/constants';

const WoosaAdjustableBedFrameWarranty: React.FC = () => {
  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO title="The Woosa Adjustable Bed" />
      <BedFrameWarrantyHeroSection />
      <BedFrameWarrantyIntroSection />
      <BedFrameWarrantyContentSection />
      <BedFrameWarrantyFooterSection />
    </Layout>
  );
};

export default WoosaAdjustableBedFrameWarranty;
